.backdrop {
  position: fixed !important;
  width: 100vw !important;
  height: 120vh !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  z-index: 200 !important;
  display: flex;
  justify-content: flex-end;
}

.drawer-wrapper {
  width: 80vw;
  height: 100% !important;
  background-color: white;
  animation: draw 0.4s ease-in-out;
}


@keyframes draw {
  from {
    width: 0;
  }
  to {
    width: 80vw;
  }
}

.drawer-header {
  width: 100%;
  height: 15vh;
  background-color: #87d675;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-wrapper {
  padding-left: 1.5rem;
  color: white;
}

.nav-drawer-item {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding: 1.3rem;
  justify-content: space-between;
  color: #5a5a5d;
  border-bottom: 0.05rem solid #5a5a5d;
}
